import React, { useState, useEffect, useContext } from 'react';
import Fade from 'react-reveal';
import { Grid, Typography } from '@mui/material';
import { ThemeContext } from 'styled-components';
import Social from './Social';
import FallbackSpinner from './FallbackSpinner';
import { Col, Container, Row } from 'react-bootstrap';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import home from '../constants/profile/home.json';
import about from '../constants/profile/about.json';


const styles = {
  nameStyle: {
    fontSize: '3em',
  },
  inlineChild: {
    display: 'inline-block',
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  introTextContainer: {
    flexDirection: 'column',
    whiteSpace: 'pre-wrap',
    textAlign: 'justify',
    fontSize: '1.2em',
    fontWeight: 500,
  },
  introImageContainer: {
    margin: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
};


function Home() {
  const theme = useContext(ThemeContext);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [data, setData] = useState(null);
  const [dataAbout, setDataAbout] = useState(null);
  const parseIntro = (text) => (
    <ReactMarkdown
      children={text}
    />
  );

  useEffect(() => {
    setData(home);
  }, []);

  useEffect(() => {
    setDataAbout(about);
  }
    , []);
  return data ? (
    <Container>
      <Fade>
        <div style={styles.mainContainer}>
          <h1 style={styles.nameStyle}>{data?.name}</h1>
          <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Typography variant="h5">
              {data?.roles}
            </Typography>
            <Typography variant="h6" style={styles.inlineChild}>
              GIPSA-lab UMR 5216/ Control & Diagnosis department / SAFE team
            </Typography>

          </div>
          <div className="section-content-container">
            <Container>
              {dataAbout
                ? (
                  !isMobile ? (
                    <Fade>
                      <Grid container style={{ borderBottom: "1px solid black", marginTop: "30px" }} spacing={2}>
                        <Grid item xs={7} style={styles.introTextContainer}>
                          {parseIntro(dataAbout[0].about)}
                        </Grid>
                        <Grid item xs={4} style={styles.introImageContainer}>
                          <img src={dataAbout[0]?.imageSource} alt="profile" width="200" height="170" style={{ height: 'auto' }} />
                        </Grid>
                      </Grid>
                      <Row>
                        <Col style={styles.introTextContainer}>
                          <Typography sx={{ color: theme.color, mb: 2, mt: 5, fontWeight: "bold" }} variant='h6'>
                            {dataAbout[1].about}
                          </Typography>
                          <Typography sx={{ color: theme.color }} align="justify">
                            {dataAbout[1].about2}
                          </Typography>
                          <Typography sx={{ color: theme.color }} align="justify">
                            {dataAbout[1].about3}
                          </Typography>
                        </Col>
                        <Col style={styles.introImageContainer}>
                          <img src={dataAbout[1]?.imageSource} alt="profile" width="300" height="180" style={{ maxWidth: '100%', height: 'auto' }} />
                        </Col>
                      </Row>
                    </Fade>

                  ) : (
                    <Fade>

                      <div style={{ borderBottom: "1px solid black" }}>
                        <Col style={styles.introImageContainer} >
                          <img src={dataAbout[0]?.imageSource} alt="profile" width="200" height="170" style={{ height: 'auto', margin: 'auto' }} />
                        </Col>
                        <Col style={styles.introTextContainer}>
                          {parseIntro(dataAbout[0].about)}
                        </Col>
                      </div>
                      <div>
                        <Col style={styles.introTextContainer}>
                          <Col style={styles.introImageContainer}>
                            <img src={dataAbout[1]?.imageSource} alt="profile" width="500" height="300" style={{ maxWidth: '100%', height: 'auto' }} />
                          </Col>
                          <Typography sx={{ color: theme.color, mb: 2, mt: 5, fontWeight: "bold" }} variant='h6'>
                            {dataAbout[1].about}
                          </Typography>
                          <Typography sx={{ color: theme.color }} align="justify">
                            {dataAbout[1].about2}
                          </Typography>
                          <Typography sx={{ color: theme.color }} align="justify">
                            {dataAbout[1].about3}
                          </Typography>
                        </Col>
                      </div>
                    </Fade>
                  )
                )
                : <FallbackSpinner />}
            </Container>
          </div>
          <Social />
        </div>
      </Fade>
    </Container >

  ) : <FallbackSpinner />;
}

export default Home;
